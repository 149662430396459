body.standalone {
  margin: 0;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

html {
  background-color: #f2f3f8;
  color: #74788d;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
